







































































































































































import Vue from "vue";
import { v4 as uuid } from "uuid";
import { validationMixin } from "vuelidate";
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import categories from "../assets/categories/books-and-education.json";
import Component from "vue-class-component";
import { Binding, Item } from "@/services/model/item";

const validations = {
  title: { required, maxLength: maxLength(100) },
  subtitle: { maxLength: maxLength(150) },
  author: { required },
  category: { required },
  condition: { required },
  binding: { required },
  price: { required, numeric },
  reference: { required },
  description: { maxLength: maxLength(1000) },
};

@Component({
  mixins: [validationMixin],
  validations: validations,
})
export default class ListItem extends Vue {
  title = "";
  subtitle = "";
  author = "";
  publisher = "";
  condition = "";
  binding: Binding = Binding.Empty;
  price = "";
  category: any = {};
  reference = "";
  description = "";

  bindingOptions = [Binding.Softcover, Binding.Hardcover];
  showDescirption = false;

  get titleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.title.$dirty) return errors;
    !this.$v.title.maxLength &&
      errors.push("Title must be at most 100 characters long");
    !this.$v.title.required && errors.push("Title is required.");
    return errors;
  }

  get subtitleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.subtitle.$dirty) return errors;
    !this.$v.subtitle.maxLength &&
      errors.push("Subtitle must be at most 150 characters long");
    return errors;
  }

  get authorErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.author.$dirty) return errors;
    !this.$v.author.required && errors.push("Author is required");
    return errors;
  }

  get referenceErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.reference.$dirty) return errors;
    !this.$v.reference.required && errors.push("Reference is required");
    return errors;
  }

  get conditionErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.condition.$dirty) return errors;
    !this.$v.condition.required && errors.push("Condition is required");
    return errors;
  }

  get bindingErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.binding.$dirty) return errors;
    !this.$v.binding.required && errors.push("Binding is required");
    return errors;
  }

  get priceErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.price.$dirty) return errors;
    !this.$v.price.numeric && errors.push("Price must be a number");
    !this.$v.price.required && errors.push("Price is required");
    return errors;
  }

  get categoryErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.category.$dirty) return errors;
    !this.$v.category.required && errors.push("Category is required");
    return errors;
  }

  get descriptionErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.description.$dirty) return errors;
    !this.$v.description.maxLength &&
      errors.push("Description must be at most 1000 characters long");
    return errors;
  }

  get categories(): any {
    return categories;
  }

  get sessionId(): string {
    return this.$store.getters["session/getActiveSessionId"];
  }

  $refs!: {
    title: HTMLFormElement;
  };

  submit() {
    this.$v.$touch();
    if (this.$v.$error) return;

    const itemId = uuid();
    const item = new Item(
      itemId,
      this.author,
      this.title,
      this.subtitle,
      this.publisher,
      this.description,
      this.category.code,
      parseFloat(this.price),
      this.condition,
      this.binding,
      this.reference,
      [],
      undefined,
      this.sessionId
    );

    this.$store
      .dispatch("session/createItem", item)
      .then((_) => this.clearForm())
      .then((_) => this.$v.$reset());
  }

  clearForm() {
    this.title = "";
    this.subtitle = "";
    this.author = "";
    this.publisher = "";
    this.description = "";
    this.condition = "";
    this.binding = Binding.Empty;
    this.reference = "";
    this.category = {};
    this.price = "";
    this.$refs.title.focus(); // focus cursor on field with ref=title
  }
}
