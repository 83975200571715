













import Vue from "vue";
import ListItem from "@/components/ListItem.vue";
import ItemTable from "@/components/ItemTable.vue";
import Component from "vue-class-component";
import { ListingSession } from "@/services/session";
import { mapGetters, mapActions } from "vuex";

@Component({
  components: {
    ListItem,
    ItemTable,
  },
  computed: mapGetters("session", ["getActiveSessionItems"]),
  methods: mapActions("session", ["getLatestSession", "populate"]),
})
export default class List extends Vue {
  getLatestSession!: () => Promise<ListingSession>;
  populate!: (id: string) => Promise<Object>;
  loaded = false;

  mounted() {
    this.getLatestSession().then((listingSession: ListingSession) => {
      this.populate(listingSession.id).then((_) => (this.loaded = true));
    });
  }
}
