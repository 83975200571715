export interface Item {
    id: string,
    author: string,
    title: string,
    subtitle: string,
    publisher: string,
    description: string,
    category: number,
    price: number,
    condition: string,
    binding: Binding,
    reference: string,
    imageIDs: string[],
    soldAt?: string,
    listingSessionID?: string
}

export class Item implements Item {

    id: string
    author: string
    title: string
    subtitle: string
    publisher: string
    description: string
    category: number
    price: number
    condition: string
    binding: Binding
    reference: string
    imageIDs: string[]
    soldAt?: string
    listingSessionID?: string

    constructor(id: string, author: string, title: string, subtitle: string, publisher: string, description: string, category: number, price: number, condition: string, binding: Binding, reference: string, imageIDs: string[], soldAt?: string, listingSessionID?: string) {
        this.id = id;
        this.author = author;
        this.title = title;
        this.subtitle = subtitle;
        this.publisher = publisher;
        this.description = description;
        this.category = category;
        this.price = price;
        this.condition = condition;
        this.binding = binding;
        this.reference = reference;
        this.listingSessionID = listingSessionID;
        this.imageIDs = imageIDs;
        this.soldAt = soldAt;
    }
}

export enum Binding {
    Empty = "",
    Softcover = "SOFTCOVER",
    Hardcover = "HARDCOVER"
}
